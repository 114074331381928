var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"intent-prompt"}},[_c('vs-prompt',{attrs:{"vs-title":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt.title[
        _vm.languageSelected
      ],"vs-active":_vm.isPromptActive,"vs-accept-text":_vm.lang.botMaker.accept[_vm.languageSelected],"vs-cancel-text":_vm.lang.botMaker.cancel[_vm.languageSelected],"vs-button-cancel":"flat"},on:{"update:vsActive":function($event){_vm.isPromptActive=$event},"vs-accept":_vm.saveIntention,"vs-close":_vm.cancelIntention,"vs-cancel":_vm.cancelIntention}},[_c('vs-row',[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-input',{directives:[{name:"intention",rawName:"v-intention"}],staticClass:"intent-prompt-input",attrs:{"label":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.form.name[
              _vm.languageSelected
            ],"placeholder":"","danger":_vm.intentHasErrors,"danger-text":_vm.intentErrorMessage},model:{value:(_vm.intent.name),callback:function ($$v) {_vm.$set(_vm.intent, "name", $$v)},expression:"intent.name"}})],1),_vm._v(" "),(!_vm.bot.legacy)?_c('vs-col',{attrs:{"vs-w":"12"}},[_c('vs-checkbox',{model:{value:(_vm.intent.derivesToAgent),callback:function ($$v) {_vm.$set(_vm.intent, "derivesToAgent", $$v)},expression:"intent.derivesToAgent"}},[_vm._v("\n          "+_vm._s(_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.form
              .derivesToAgent[_vm.languageSelected])+"\n        ")]),_vm._v(" "),_c('br')],1):_vm._e(),_vm._v(" "),(!_vm.bot.legacy)?_c('vs-col',{attrs:{"vs-w":"12"}},[_c('div',{ref:"genButton",staticClass:"intent-examples vs-con-loading__container"},[_c('vs-input',{staticClass:"intent-prompt-input",attrs:{"label":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.form.examples[
                _vm.languageSelected
              ],"placeholder":"","danger":_vm.example !== '' ? _vm.exampleHasError : false,"danger-text":_vm.example !== '' ? _vm.exampleErrorMessage : ''},on:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addExample($event)}},model:{value:(_vm.example),callback:function ($$v) {_vm.example=$$v},expression:"example"}}),_vm._v(" "),_c('vx-tooltip',{attrs:{"text":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.form
                .addExample[_vm.languageSelected]}},[_c('vs-button',{staticClass:"add-intent-btn",attrs:{"icon":"add"},on:{"click":_vm.addExample}})],1),_vm._v(" "),_c('vx-tooltip',{attrs:{"text":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.form
                .genExample[_vm.languageSelected]}},[_c('vs-button',{staticClass:"add-intent-btn",attrs:{"disabled":!_vm.intent.name,"icon":"auto_fix_high"},on:{"click":_vm.generateExamples}})],1)],1),_vm._v(" "),_c('div',{staticClass:"intent-examples-list"},[_c('div',{staticClass:"vs-list"},_vm._l((_vm.intent.examples),function(example,index){return _c('div',{key:index,staticClass:"vs-list--item"},[_c('div',{staticClass:"list-titles"},[(index !== _vm.editingExampleIndex)?_c('span',[_vm._v("\n                  "+_vm._s(example)+"\n                ")]):_c('div',{staticClass:"flex"},[_c('vs-input',{staticClass:"mr-2",on:{"keyup":[function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.saveExample($event)},function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.revertExample(index)}]},model:{value:(_vm.intent.examples[index]),callback:function ($$v) {_vm.$set(_vm.intent.examples, index, $$v)},expression:"intent.examples[index]"}},[_vm._v("\n                    "+_vm._s(example)+"\n                  ")]),_vm._v(" "),_c('vs-button',{attrs:{"color":"primary","type":"flat","icon":"check","radius":""},on:{"click":_vm.saveExample}}),_vm._v(" "),_c('vs-button',{attrs:{"color":"primary","type":"flat","icon":"clear","radius":""},on:{"click":function($event){_vm.revertExample(index)}}})],1)]),_vm._v(" "),_c('div',{staticClass:"vs-list--slot"},[_c('div',{staticClass:"flex"},[_c('vs-button',{attrs:{"color":"primary","type":"flat","icon":"edit","radius":""},on:{"click":function($event){_vm.editExample(index)}}}),_vm._v(" "),_c('vs-button',{attrs:{"color":"primary","type":"flat","icon":"clear","radius":""},on:{"click":function($event){_vm.removeExample(example)}}})],1)])])}))])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }