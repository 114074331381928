<template>
  <div id="intent-prompt">
    <vs-prompt
      :vs-title="
        lang.botMaker.addBot.intentionTriggerSecondLevel.prompt.title[
          languageSelected
        ]
      "
      :vs-active.sync="isPromptActive"
      :vs-accept-text="lang.botMaker.accept[languageSelected]"
      :vs-cancel-text="lang.botMaker.cancel[languageSelected]"
      vs-button-cancel="flat"
      @vs-accept="saveIntention"
      @vs-close="cancelIntention"
      @vs-cancel="cancelIntention"
    >
      <vs-row>
        <vs-col vs-w="12">
          <vs-input
            class="intent-prompt-input"
            :label="
              lang.botMaker.addBot.intentionTriggerSecondLevel.form.name[
                languageSelected
              ]
            "
            placeholder
            v-model="intent.name"
            :danger="intentHasErrors"
            :danger-text="intentErrorMessage"
            v-intention
          />
        </vs-col>

        <vs-col vs-w="12" v-if="!bot.legacy">
          <vs-checkbox v-model="intent.derivesToAgent">
            {{
              lang.botMaker.addBot.intentionTriggerSecondLevel.form
                .derivesToAgent[languageSelected]
            }}
          </vs-checkbox>
          <br />
        </vs-col>

        <vs-col vs-w="12" v-if="!bot.legacy">
          <div
            class="intent-examples vs-con-loading__container"
            ref="genButton"
          >
            <vs-input
              class="intent-prompt-input"
              v-on:keyup.enter="addExample"
              :label="
                lang.botMaker.addBot.intentionTriggerSecondLevel.form.examples[
                  languageSelected
                ]
              "
              placeholder
              v-model="example"
              :danger="example !== '' ? exampleHasError : false"
              :danger-text="example !== '' ? exampleErrorMessage : ''"
            />
            <vx-tooltip
              :text="
                lang.botMaker.addBot.intentionTriggerSecondLevel.form
                  .addExample[languageSelected]
              "
            >
              <vs-button
                class="add-intent-btn"
                @click="addExample"
                icon="add"
              />
            </vx-tooltip>
            <vx-tooltip
              :text="
                lang.botMaker.addBot.intentionTriggerSecondLevel.form
                  .genExample[languageSelected]
              "
            >
              <vs-button
                class="add-intent-btn"
                @click="generateExamples"
                :disabled="!intent.name"
                icon="auto_fix_high"
              />
            </vx-tooltip>
          </div>
          <div class="intent-examples-list">
            <div class="vs-list">
              <div
                class="vs-list--item"
                v-for="(example, index) in intent.examples"
                :key="index"
              >
                <div class="list-titles">
                  <span v-if="index !== editingExampleIndex">
                    {{ example }}
                  </span>

                  <div v-else class="flex">
                    <vs-input
                      class="mr-2"
                      v-model="intent.examples[index]"
                      @keyup.enter="saveExample"
                      @keyup.esc="revertExample(index)"
                    >
                      {{ example }}
                    </vs-input>

                    <vs-button
                      color="primary"
                      type="flat"
                      icon="check"
                      radius
                      @click="saveExample"
                    />
                    <vs-button
                      color="primary"
                      type="flat"
                      icon="clear"
                      radius
                      @click="revertExample(index)"
                    />
                  </div>
                </div>

                <div class="vs-list--slot">
                  <div class="flex">
                    <vs-button
                      color="primary"
                      type="flat"
                      icon="edit"
                      radius
                      @click="editExample(index)"
                    />
                    <vs-button
                      color="primary"
                      type="flat"
                      icon="clear"
                      radius
                      @click="removeExample(example)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vs-prompt>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { Intent } from '../../../../../models/Intent'
import { errorHandler } from '@/services/ErrorHandler'
import BotMakerService from '@/services/botMaker.service'

export default {
  name: 'IntentsPrompt',
  props: {
    togglePrompt: {
      type: Function,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    fromCatalog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      example: '',
      intent: {},
      validation: {
        emptyExample: false,
        duplicateExample: false,
        emptyIntent: false,
        duplicateIntent: false
      },
      intentWithExample: null,
      editingExampleIndex: null,
      previousExample: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    ...mapGetters('bots', [
      'bot',
      'editIntentionInternalId',
      'usersByChannelMap'
    ]),
    ...mapGetters({ botMakerBot: 'botMaker/bot' }),
    isPromptActive: {
      get() {
        return this.$store.state.bots.isAddIntentOpen
      },
      set() {
        return true
      }
    },
    intentHasErrors() {
      return this.validation.emptyIntent || this.validation.duplicateIntent
    },
    intentErrorMessage() {
      if (this.validation.emptyIntent) {
        return this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
          .error.intent.empty[this.languageSelected]
      }

      if (this.validation.duplicateIntent) {
        return this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
          .error.intent.duplicate[this.languageSelected]
      }
    },
    exampleHasError() {
      return this.validation.emptyExample || this.validation.duplicateExample
    },
    exampleErrorMessage() {
      if (this.validation.emptyExample) {
        return this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
          .error.examples.empty[this.languageSelected]
      }

      return this.intentWithExample &&
        this.intentWithExample.name &&
        this.intentWithExample.name !== this.intent.name
        ? `${
            this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt.error
              .examples.duplicate[this.languageSelected]
          } ${
            this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt.error
              .examples.intentionInfo[this.languageSelected]
          } "${this.intentWithExample.name}"`
        : this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt.error
            .examples.duplicate[this.languageSelected]
    }
  },
  methods: {
    ...mapMutations('bots', [
      'SET_ADD_INTENT_PROMPT',
      'RESET_ADD_BOT',
      'SET_INTENTION_ID_TO_EDIT',
      'SET_BOT'
    ]),
    ...mapMutations('integrations', ['RESET_BOT_CHANNELS', 'START_STORE']),
    ...mapActions('bots', [
      'GET_VERSION_INTENTS',
      'SAVE_INTENT',
      'UPDATE_INTENT',
      'SET_INTENT_DERIVATE'
    ]),
    resetValidation() {
      this.validation = {
        emptyExample: false,
        duplicateExample: false,
        emptyIntent: false,
        duplicateIntent: false,
        emptyBotResponse: false
      }
    },
    async updateBot() {
      await this.SET_INTENT_DERIVATE(this.intent)
      //const activeVersion = this.bot.activeVersion + ''
      //const intents = Object.assign(this.bot.intents)
      await this.bot.updateBot(this.session.user.roles, this.usersByChannelMap, this.useStaffManagement)
      return true
    },
    validate() {
      this.validation.emptyIntent = !this.intent.isNameValid()
      this.validation.duplicateIntent = this.intent.id
        ? this.bot.hasIntent(this.intent.name, true)
        : this.bot.hasIntent(this.intent.name)

      if (!this.bot.legacy) {
        this.validation.emptyExample = !this.intent.isExamplesValid()
      }
      this.$log.info(this.validation)
    },
    async saveIntention() {
      this.validate()
      if (Object.values(this.validation).some(elem => elem)) {
        this.$log.error('Intention has errors')
        return
      }

      this.resetValidation()
      if (!this.bot.legacy && this.bot.isSaved) {
        const intentPayload = {
          intent: this.intent,
          version: this.botMakerBot.activeVersion || null
        }
        try {
          this.$vs.loading()
          if (this.intent.id) {
            // edit intent
            await this.UPDATE_INTENT(this.intent)
            await this.updateBot()
            this.$notify.success(
              this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
                .success.title[this.languageSelected],
              this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
                .successUpdate[this.languageSelected]
            )
            this.togglePrompt()
          } else {
            // create intent
            await this.SAVE_INTENT(intentPayload)
            await this.updateBot()
            this.$emit('onSave')
            this.$notify.success(
              this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
                .success.title[this.languageSelected],
              this.lang.botMaker.addBot.intentionTriggerSecondLevel.prompt
                .success.text[this.languageSelected]
            )
            this.togglePrompt()
            this.$emit('resetSearch', true)
          }
          this.$vs.loading.close()
        } catch (error) {
          errorHandler(error)
          this.$vs.loading.close()
        }
      } else {
        // lagacy bot
        if (!this.intent.isSaved) {
          this.intent.isSaved = true

          const intentName = this.bot.intents.find(
            intent => intent.name === this.intent.name
          )

          if (!intentName) {
            // new intent name
            this.bot.intents.push(this._.cloneDeep(this.intent))
          }
        } else {
          let savedIntent = this._.find(
            this.bot.intents,
            i => i.internalId === this.intent.internalId
          )
          Object.assign(savedIntent, this.intent)
        }
        this.togglePrompt()
      }
    },
    // TODO: move to utils
    normalizeString(string) {
      if (typeof string !== 'string') return null
      return string
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/\s\s+/g, ' ')
        .replace(/[\u0300-\u036f]/g, '')
    },
    addExample() {
      this.validation.emptyExample =
        this.example.replace(/\s/g, '').length === 0

      if (this.validation.emptyExample) return

      const intentsWithoutCurrent = this.bot.intents.filter(
        i => i.name !== this.intent.name
      )
      this.intentWithExample =
        intentsWithoutCurrent.find(intent => intent.hasExample(this.example)) ||
        this.intent.examples
          .map(e => this.normalizeString(e))
          .find(e => e === this.normalizeString(this.example))

      this.validation.duplicateExample = this.intentWithExample !== undefined

      if (this.validation.duplicateExample) return

      this.resetValidation()
      this.intent.examples.push(this.example)
      this.example = ''
    },
    async generateExamples() {
      try {
        this.$vs.loading({ container: this.$refs.genButton, scale: 0.6 })

        const payload = {
          intentName: this.intent.name,
          lang: this.bot.nlu.culture.split('-')[0]
        }
        const response = await BotMakerService.getGeneratedExamples(payload)
        const generatedExamples =
          response && response.data && response.data.paraphrased

        this.intent.examples.push(...generatedExamples)
        // remove duplicated examples
        this.intent.examples = [...new Set(this.intent.examples)]
        this.$vs.loading.close(this.$refs.genButton)
      } catch (error) {
        this.$vs.loading.close(this.$refs.genButton)
        this.$vs.notify({
          text: this.lang.botMaker.addBot.intentionTriggerSecondLevel.form
            .genExampleError[this.languageSelected],
          color: 'danger'
        })
      }
    },
    editExample(index) {
      this.previousExample = this.intent.examples[index]
      this.editingExampleIndex = index
    },
    revertExample(index) {
      this.intent.examples[index] = this.previousExample
      this.editingExampleIndex = null
    },
    saveExample() {
      this.previousExample = null
      this.editingExampleIndex = null
    },
    removeExample(e) {
      this.intent.examples = this.intent.examples.filter(example => {
        return example !== e
      })
    },
    cancelIntention() {
      this.togglePrompt()
    }
  },
  mounted() {
    this.intent = new Intent(this.name)

    if (this.bot.legacy) {
      this.intent.derivesToAgent = true
    }

    if (this.editIntentionInternalId) {
      const intentToEdit = this._.find(
        this.bot.intents,
        intent => intent.internalId === this.editIntentionInternalId
      )
      this.SET_INTENTION_ID_TO_EDIT(undefined)
      this.intent = this._.cloneDeep(intentToEdit)
    }
  }
}
</script>

<style lang="scss">
.intent-prompt-input {
  width: 100%;
  margin-bottom: 20px;
}
.intent-examples {
  display: flex;
  align-items: center;
}
.intent-input {
  width: 50%;
  display: flex;
  align-items: center;
}
.add-intent-btn {
  margin-left: 5px;
}
.intent-example {
  display: flex;
  align-items: center;
}
.clear-intent-btn {
  margin-right: 5px;
}
.intent-examples-list {
  max-height: 400px;
  overflow-y: auto;
}
.con-vs-dialog .vs-dialog footer button:not(:first-of-type) {
  background: rgba(var(--vs-danger), 1) !important;
}
.con-vs-dialog .vs-dialog footer {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: flex-end;
}
.con-vs-dialog .vs-dialog footer .vs-button {
  margin-left: 0.5rem;
}
.con-vs-dialog .vs-dialog {
  max-width: 650px;
}
.span-text-validation-danger {
  height: unset !important;
}
</style>
